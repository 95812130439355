import React, { Component } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Introduction from "../components/introduction"
import Spacer from "../components/spacer"
import CenteredSlider from "../components/centered-slider"
import CenteredSlide from "../components/centered-slide"

class NotFoundPage extends Component {
  render() {
    const data = this.props.data

    return (
      <Layout background={ true }>
        <SEO title="404: Not found" />
        <Introduction 
          title="404 Page not found"
          subtitle={<><p>Sorry but the page you are looking for can't be found.</p></>}
        />
        <Spacer space="4" />
        <CenteredSlider>
          {data.allWordpressPost.edges.map(({ node }) => (
            <CenteredSlide key={node.id} title={node.title} image={ node.featured_media.localFile.childImageSharp.original.src } link={node.path} />
          ))}
        </CenteredSlider>         
      </Layout>
    )
  }
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    allWordpressPost(sort: {fields: [date], order: DESC}) {
      edges {
        node {
          id
          slug
          path
          title
          featured_media {
            localFile {
              childImageSharp {
                original {
                  src
                }
              }
            }
          }        
        }
      }
    }
  }
`